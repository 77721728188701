body {

    .btn svg {
        fill: #FFFFFF;
        stroke: var(--ionicon-stroke-width,32px);
    }

    .appBottomMenu {
        svg {
            fill: $primary;
        }
    }

    .appHeader {
        /*background: #039be5;*/
        color: white;
    }

    .pageTitle svg {
        margin-right: 0.25rem;
    }

    .appHeader .left .headerButton,
    .appHeader .right .headerButton {    
        svg {
            fill: #FFFFFF;
        }

        .btn-success {
            svg {
                fill: #FFFFFF;
            }
        }
    }
    .appHeader, .modal-header {
        .pageTitle svg {
            /*fill: #6236FF;*/
            fill: white;
        }
    }
}

.appHeader {
    .pageTitle {
        svg {
            height: 20px;
            width: 20px;
            margin-right: 0.25em;
            vertical-align: middle;
        }
    }
}

.panelbox .modal-dialog .modal-content .modal-header {
    background-color: $primary;
    color: white;
    display: flex;
    align-items: center;

    .modal-title {
        color: white;
        
        .pageTitle {
            margin-bottom: 0;
            svg {
                height: 27px;
                width: 27px;
                margin-right: 0.25em;
                vertical-align: text-bottom;
            }
        }
    }

    button.close {
        color: white;
        opacity: 1.0;
        font-weight: lighter;
    }
}

.tabbed-listview {
    .tabbed-listview-header {
        &.card {
            /*border-radius: 10px 10px 0 0;*/
            /*box-shadow:  1px 0px 0 rgba(0, 0, 0, 0.09);*/
            
            .card-body {
                padding: 0;
            }
        }
        .card-header-tabs {
            margin-right: 0;
            margin-bottom: 0;
            margin-left: 0;
        }
    }
    .listview {
        /*border-radius: 0 0 10px 10px;*/
        border-top-color: transparent;
        border-radius: 10px;
    }
}

.custom-file-upload {
    label {
        svg {
            fill: currentColor;
        }
    }
}