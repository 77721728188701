#host {

    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: #333;
    z-index: 1000;
  }
  #host.hidden {
    display: none;
  }
  #install {
    padding: 10px;
    background: #FFFFFF;
    /*width: 280px;*/
    border: 1px solid lightgray;
    line-height: 30px;
    border-radius: 10px;
  }
  #share {
    vertical-align: top;
  }
  #arrowOuter {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid lightgray;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #arrowInner {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -19px;
  }
  #close {
    position: absolute;
    top: 0px;
    right: 7px;

    padding: 0px;
    cursor: pointer;

  }